/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import {
  db,
  periodsCollection,
  factorsCollection,
  functions,
  subfactorsCollection,
} from '../../firebase';

const defaultProgress = {
  currentStep: 0,
  lastFinishedStep: 0,
  hasStarted: false,
  hasFinished: false,
  pointsMultiplier: 10,
};

const state = () => ({
  progress: defaultProgress,
  isLoading: false,
  selectedPeriod: {},
  steps: [
    {
      name: 'Factores',
      subtitle: 'Factores',
      icon: 'puzzle-piece',
      description:
        'Son cuatro y se ordenan de acuerdo al grado de importancia que tienen en la organización',
    },
    {
      name: 'Subfactores',
      subtitle: 'Sub Factores',
      icon: 'search-alt',
      description:
        'Son características mas detalladas de los factores y se eligen de acuerdo al análisis realizado en cada uno de estos.',
    },
    // {
    // name: 'Calibración',
    // subtitle: 'Calibración',
    // icon: 'ruler-combined',
    // description:
    // 'Se utiliza para identificar, ponderar y
    // equilibrar la puntuación de los sub factores para una mejor valorización de puestos.',
    // },
    {
      name: 'Niveles',
      subtitle: 'Grados o Niveles',
      icon: 'tachometer-fast',
      description:
        'Se utiliza para identificar el grado de dificultad e importancia de cada posición en la organización.',
    },
    { name: 'Resumen', icon: 'eye' },
  ],
  periods: [],
  factors: [],
  subfactors: [],
  isPointsConfigModalOpen: false,
  hasFinishedSuccessfuly: false,
});

const getters = {
  getSubfactorName(state) {
    return (subfactorId) => {
      const subfactor = state.subfactors.find((sf) => sf.id === subfactorId);
      return subfactor ? subfactor.name : '';
    };
  },
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = !!value;
  },

  setHasFinishedSuccessfuly(state, value) {
    state.hasFinishedSuccessfuly = value;
  },

  setIsPointsConfigModalOpen(state, value) {
    state.isPointsConfigModalOpen = !!value;
  },

  setSelectedPeriod(state, period) {
    state.selectedPeriod = period;
    state.progress = period.jobEvaluationProcess;
  },

  setPeriods(state, periods) {
    state.periods = periods;
  },

  addPeriod(state, period) {
    state.periods = [...state.periods, period];
  },

  setFactors(state, factors) {
    state.factors = factors;
  },

  setSubfactors(state, subfactors) {
    state.subfactors = subfactors;
  },

  setProgress(state, progress) {
    state.progress = progress;
  },

  finishProcess(state) {
    state.progress.hasFinished = true;
    state.progress.currentStep = 0;
    state.hasFinishedSuccessfuly = false;
  },

  resetProgress(state) {
    state.progress = defaultProgress;

    state.factors = state.factors.map((factor) => ({
      ...factor,
      percentage: 0,
      value: 0,
      subfactors: [],
    }));
  },

  updatePointsMultiplier(state, value) {
    state.progress.pointsMultiplier = value;
  },

  goToStep(state, step) {
    if (typeof step !== 'number' || step < 0 || step >= 5) {
      throw new Error('Invalid value');
    }

    if (!state.progress.hasStarted) {
      state.progress.hasStarted = true;
    }

    state.progress.currentStep = step;
  },

  goToNextStep(state) {
    if (state.progress.currentStep >= 4) return;

    if (!state.progress.hasStarted) {
      state.progress.hasStarted = true;
    }

    if (state.progress.lastFinishedStep < state.progress.currentStep) {
      state.progress.lastFinishedStep = state.progress.currentStep;
    }

    state.progress.currentStep += 1;
  },

  goToPreviousStep(state) {
    if (state.progress.currentStep <= 0) return;
    state.progress.currentStep -= 1;
  },

  updateFactorsOrder(state, value) {
    state.factors = value.map((factor, index) => ({ ...factor, index }));
  },

  updateFactorPercentage(state, { id, percentage }) {
    state.factors = state.factors.map((factor) => {
      if (factor.id === id) return { ...factor, percentage };
      return factor;
    });
  },

  updateFactorPercentageCopy(state, { id, percentage }) {
    state.factors = state.factors.map((factor) => {
      if (factor.id === id) return { ...factor, percentageCopy: percentage };
      return factor;
    });
  },

  updateSelectedSubfactorsOrder(state, factorId) {
    const factor = state.factors.find((f) => f.id === factorId);
    factor.subfactors = factor.subfactors.sort((a, b) => b.percentage - a.percentage);
  },

  addSubfactorToFactor(state, { factorId, subfactor, options = [] }) {
    const newSubfactor = {
      id: subfactor.id,
      name: subfactor.name,
      description: subfactor.description,
      percentage: 0,
      value: 0,
      options: options.map((opt, index) => ({
        name: opt,
        value: 0,
        index: index + 1,
      })),
    };

    const factor = state.factors.find((f) => f.id === factorId);
    if (factor.subfactors.find(({ id }) => id === subfactor.id) === undefined) {
      factor.subfactors.push(newSubfactor);
    }
  },

  removeSubfactorFromFactor(state, { factorId, subfactorId }) {
    const removeSubfactor = (subfactors) => subfactors.filter((sf) => sf.id !== subfactorId);

    state.factors = state.factors.map((factor) => {
      if (factor.id === factorId) {
        return { ...factor, subfactors: removeSubfactor(factor.subfactors) };
      }
      return factor;
    });
  },

  updateSubfactorPercentage(state, { factorId, subfactorId, percentage }) {
    const factor = state.factors.find((f) => f.id === factorId);

    if (factor) {
      const subfactor = factor.subfactors.find((sf) => sf.id === subfactorId);

      if (subfactor) {
        subfactor.percentage = percentage;
      }
    }
  },

  updateSubfactorPercentageCopy(state, { factorId, subfactorId, percentage }) {
    const factor = state.factors.find((f) => f.id === factorId);

    if (factor) {
      const subfactor = factor.subfactors.find((sf) => sf.id === subfactorId);

      if (subfactor) {
        subfactor.percentageCopy = percentage;
      }
    }
  },

  addSubfactor(state, subfactor) {
    state.subfactors = [...state.subfactors, subfactor];
  },

  deleteSubfactor(state, subfactorId) {
    state.subfactors = state.subfactors.filter((subfactor) => subfactor.id !== subfactorId);
  },

  updateSubfactor(state, { id, name, description }) {
    state.subfactors = state.subfactors.map((sf) => {
      if (sf.id === id) return { ...sf, name, description };
      return sf;
    });
  },

  createPercentagesCopy(state) {
    state.factors = state.factors.map((factor) => ({
      ...factor,
      percentageCopy: factor.percentage,
      subfactors: factor.subfactors.map((sf) => ({
        ...sf,
        percentageCopy: sf.percentage,
      })),
    }));
  },

  setPercentagesCopyAsOriginal(state) {
    state.factors = state.factors.map(({ percentageCopy: factorPercentageCopy, ...factor }) => ({
      ...factor,
      percentage: factorPercentageCopy || factor.percentage,
      subfactors: factor.subfactors.map(({ percentageCopy, ...sf }) => ({
        ...sf,
        percentage: percentageCopy || sf.percentage,
      })),
    }));
  },

  setValues(state) {
    const { pointsMultiplier } = state.progress;

    state.factors = state.factors.map((factor) => ({
      ...factor,
      value: factor.percentage * pointsMultiplier,
      subfactors: factor.subfactors.map((sf) => {
        const subfactorValue = sf.percentage * pointsMultiplier;
        const optionsMultiplier = subfactorValue / sf.options.length;
        const { length } = sf.options;

        return {
          ...sf,
          value: subfactorValue,
          options: sf.options.map((opt, index) => ({
            ...opt,
            value: Math.ceil((length - index) * optionsMultiplier),
          })),
        };
      }),
    }));
  },

  addOptionToSubfactor(state, { factorId, subfactorId }) {
    const factor = state.factors.find((f) => f.id === factorId);

    const subfactor = factor.subfactors.find((sf) => sf.id === subfactorId);

    subfactor.options.push({
      name: '',
      value: 0,
    });
  },

  removeOptionFromSubfactor(state, { factorId, subfactorId, index }) {
    const factor = state.factors.find((f) => f.id === factorId);

    const subfactor = factor.subfactors.find((sf) => sf.id === subfactorId);

    subfactor.options.splice(index, 1);
  },

  updateOptionName(state, {
    factorId,
    subfactorId,
    index,
    name,
  }) {
    const factor = state.factors.find((f) => f.id === factorId);

    const subfactor = factor.subfactors.find((sf) => sf.id === subfactorId);

    subfactor.options[index].name = name;
  },

  updateOptionsOrder(state, { factorId, subfactorId, options }) {
    const factor = state.factors.find((f) => f.id === factorId);

    const subfactor = factor.subfactors.find((sf) => sf.id === subfactorId);

    subfactor.options = options;
  },

  recalculateOptionsValues(state, { factorId, subfactorId }) {
    const factor = state.factors.find((f) => f.id === factorId);

    const subfactor = factor.subfactors.find((sf) => sf.id === subfactorId);

    const { length } = subfactor.options;
    const multiplier = subfactor.value / length;

    subfactor.options = subfactor.options.map((option, index) => ({
      ...option,
      value: Math.ceil((length - index) * multiplier),
    }));
  },
};

const actions = {
  async fetchPeriods({ commit, rootState }) {
    const companyId = rootState.company.id;

    const periodSnaps = await periodsCollection(companyId).orderBy('index').get();

    const periods = periodSnaps.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    commit('setPeriods', periods);
  },

  async addPeriod({ commit, rootState }, { id, isACopy, ...period }) {
    const companyId = rootState.company.id;

    const newPeriod = await periodsCollection(companyId).add({
      jobEvaluationProcess: isACopy ? {} : defaultProgress,
      ...period,
    });

    commit('addPeriod', newPeriod);
  },

  async fetchFactors({ commit, rootState }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    const factorSnaps = await factorsCollection(companyId, periodId).orderBy('index').get();

    const factors = factorSnaps.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    commit('setFactors', factors);
  },

  async fetchSubfactors({ commit, rootState }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    const subfactorSnaps = await subfactorsCollection(companyId, periodId).get();

    const subfactors = subfactorSnaps.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    commit('setSubfactors', subfactors);
  },

  async saveProgress({ commit, rootState, state }) {
    commit('setIsLoading', true);

    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    const batch = db.batch();

    const { lastFinishedStep } = state.progress;

    batch.update(periodsCollection(companyId).doc(periodId), {
      jobEvaluationProcess: {
        ...state.progress,
        lastFinishedStep: lastFinishedStep >= 4 ? lastFinishedStep : lastFinishedStep + 1,
      },
    });

    state.factors.forEach(({ id, ...factor }) => {
      batch.set(factorsCollection(companyId, periodId).doc(id), factor);
    });

    await batch.commit();

    commit('setIsLoading', false);
  },

  async updateSubfactor({ commit, rootState }, {
    id, name, description,
  }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    await subfactorsCollection(companyId, periodId).doc(id).update({ name, description });

    commit('updateSubfactor', { id, name, description });
  },

  async deleteSubfactor({ commit, rootState }, { id }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    await subfactorsCollection(companyId, periodId).doc(id).delete();

    commit('deleteSubfactor', id);
  },

  async addSubfactor({ commit, rootState }, {
    factorId, name, description,
  }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    const { id } = await subfactorsCollection(companyId, periodId)
      .add({ factorId, name, description });

    commit('addSubfactor', {
      id,
      factorId,
      name,
      description,
    });
  },

  async resetProgress({ commit, dispatch }) {
    dispatch('setIsLoading', true, { root: true });
    functions
      .httpsCallable('onRestartPunctuation')()
      .then((result) => {
        commit('resetProgress', result.data);
        dispatch('setIsLoading', false, { root: true });
      });
  },

  async updatePointsMultiplier({ commit, rootState }, newMultiplier) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    await periodsCollection(companyId).doc(periodId).update({
      'jobEvaluationProcess.pointsMultiplier': newMultiplier,
    });

    commit('updatePointsMultiplier', newMultiplier);
    commit('setValues');
  },

  async finishProcess({ commit, dispatch, rootState }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    await dispatch('saveProgress', periodId);

    await periodsCollection(companyId).doc(periodId).update({
      'jobEvaluationProcess.hasFinished': true,
      'jobEvaluationProcess.currentStep': 0,
    });

    commit('setHasFinishedSuccessfuly', true);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
