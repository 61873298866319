/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import {
  evaluationsCollection,
  factorsCollection,
  firestore,
  optionsCollection,
} from '../../firebase';

const state = () => ({
  positions: [],
  areas: [],
  managements: [],
  factors: [],
  evaluations: [],
});

const mutations = {
  setPositions(state, positions) {
    state.positions = positions;
  },
  setManagements(state, managements) {
    state.managements = managements;
  },
  setAreas(state, areas) {
    state.areas = areas;
  },
  setFactors(state, factors) {
    state.factors = factors;
  },
  setEvaluations(state, evaluations) {
    state.evaluations = evaluations;
  },
  addEvaluation(state, evaluation) {
    state.positions = state.positions.map((pos) => {
      if (pos.id === evaluation.positionId) return { ...pos, evaluation };
      return pos;
    });
  },
  updateEvaluation(state, evaluation) {
    const evaluationIndex = state.evaluations.findIndex((ev) => ev.id === evaluation.id);

    if (evaluationIndex >= 0) {
      state.evaluations.splice(evaluationIndex, 1, evaluation);
    } else {
      state.evaluations.push(evaluation);
    }
  },
};

const actions = {
  async getPositions({ commit, rootState }) {
    const companyId = rootState.company.id;

    const positionSnaps = await optionsCollection(companyId)
      .orderBy('index')
      .where('categoryId', '==', 'puesto')
      .get();

    const positions = positionSnaps.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    commit('setPositions', positions);
  },

  async getManagements({ commit, rootState }) {
    const companyId = rootState.company.id;

    const managementSnaps = await optionsCollection(companyId)
      .where('categoryId', '==', 'gerencia')
      .get();

    const managements = managementSnaps.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    commit('setManagements', managements);
  },

  async getAreas({ commit, rootState }) {
    const companyId = rootState.company.id;

    const areaSnaps = await optionsCollection(companyId).where('categoryId', '==', 'area').get();

    const areas = areaSnaps.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    commit('setAreas', areas);
  },

  async getFactors({ commit, rootState }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    const factorSnaps = await factorsCollection(companyId, periodId).orderBy('index').get();

    const factors = factorSnaps.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    commit('setFactors', factors);
  },

  async getEvaluations({ commit, rootState }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    const evaluationSnaps = await evaluationsCollection(companyId, periodId).get();

    evaluationSnaps.docs.forEach((doc) => {
      commit('addEvaluation', { ...doc.data(), id: doc.id });
    });
  },

  async saveEvaluation({ commit, rootState }, { id, ...evaluation }) {
    const companyId = rootState.company.id;
    const periodId = rootState.jobEvaluationProcess.selectedPeriod.id;

    const evaluationDoc = evaluationsCollection(companyId, periodId).doc(id);

    await evaluationDoc.set({
      ...evaluation,
      dateEvaluated: firestore.FieldValue.serverTimestamp(),
      evaluatedBy: rootState.userProfile.name,
    });

    commit('addEvaluation', {
      id: evaluationDoc.id,
      ...evaluation,
      evaluatedBy: rootState.userProfile.name,
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
