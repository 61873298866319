import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '../firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        redirect: '/inicio',
      },
      {
        path: '/inicio',
        name: 'Home',
        component: () => import('../views/StartMenu.vue'),
      },
      {
        path: '/ajustes',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
      },
      {
        path: '/personas-y-puestos',
        name: 'PeopleAndJob',
        component: () => import('../views/employees/Employees.vue'),
      },
      {
        path: '/personas-y-puestos/empleados',
        name: 'Employees',
        component: () => import('../views/employees/Employees.vue'),
      },
      {
        path: '/personas-y-puestos/historial',
        name: 'History',
        component: () => import('../views/employees/History.vue'),
      },
      {
        path: '/personas-y-puestos/empleados/editar/:id',
        props: true,
        name: 'EditEmployee',
        component: () => import('../views/employees/EditEmployee.vue'),
      },
      {
        path: '/personas-y-puestos/empleados/agregar',
        props: true,
        name: 'AddEmployee',
        component: () => import('../views/employees/AddEmployee.vue'),
      },
      {
        path: '/equidad',
        name: 'Equity',
        component: () => import('../views/employees/Employees.vue'),
      },
      {
        path: '/equidad-de-genero',
        name: 'Gender',
        component: () => import('../views/equity/Gender.vue'),
      },
      {
        path: '/brecha-salarial',
        name: 'WageGap',
        component: () => import('../views/wage-gap/WageGap.vue'),
      },
      {
        path: '/brecha-salarial/puestos',
        name: 'Positions',
        component: () => import('../views/wage-gap/Positions.vue'),
        props: true,
      },
      {
        path: '/brecha-salarial/puestos/rangos',
        name: 'Range',
        component: () => import('../views/wage-gap/Range.vue'),
        props: true,
      },
      {
        path: '/valorizacion-de-puestos/valorizacion',
        name: 'JobEvaluation',
        component: () => import('../views/job-evaluation/JobEvaluation.vue'),
      },
      {
        path: '/valorizacion-de-puestos/puntuacion',
        name: 'JobEvaluationProcess',
        component: () => import('../views/job-evaluation-process/JobEvaluationProcess.vue'),
      },
      {
        path: '/valorizacion-de-puestos/valorizacion/mapa',
        name: 'JobEvaluationMap',
        component: () => import('../views/job-evaluation/JobEvaluationMap.vue'),
      },
      {
        path: '/valorizacion-de-puestos/valorizacion/sesgo',
        name: 'PositionBiasMap',
        component: () => import('../views/job-evaluation/PositionBiasMap.vue'),
      },
      {
        path: '/estructura-salarial-interna',
        name: 'InSalaryStructure',
        component: () => import('../views/internal-salary-structure/SalaryStructure.vue'),
        props: true,
      },
      {
        path: '/estructura-salarial-interna/:positionId',
        name: 'PositionEmployees',
        component: () => import('../views/internal-salary-structure/PositionEmployees.vue'),
        props: true,
      },
      {
        path: '/estructura-salarial-externa',
        name: 'ExtSalaryStructure',
        component: () => import('../views/external-salary-structure/SalaryStructure.vue'),
        props: true,
        meta: {
          requiredRole: 'pay',
        },
      },
      {
        path: '/estructura-salarial-externa/:positionId',
        name: 'EmployeesByPosition',
        component: () => import('../views/external-salary-structure/EmployeesByPosition.vue'),
        props: true,
        meta: {
          requiredRole: 'pay',
        },
      },
      {
        path: '/gestion-de-documentos/documentos',
        name: 'Files',
        component: () => import('../views/files-management/Files.vue'),
        props: true,
      },
      {
        path: '/gestion-de-documentos/correos',
        name: 'Emails',
        component: () => import('../views/files-management/Emails.vue'),
        props: true,
      },
      {
        path: '/gestion-de-documentos/correos/:id',
        name: 'Addresses',
        component: () => import('../views/files-management/Addresses.vue'),
        props: true,
      },
      {
        path: '/gestion-de-documentos/correos/:id/:emailId',
        name: 'Events',
        component: () => import('../views/files-management/Events.vue'),
        props: true,
      },
      {
        path: '/admin',
        name: 'Admin',
        component: () => import('../views/Admin.vue'),
        props: true,
        meta: {
          requiredRole: 'superadmin',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const { requiredRole } = to.meta;
  if (requiresAuth && !auth.currentUser) {
    next('/login');
  } else if (requiredRole) {
    auth.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        console.log(idTokenResult);
        if (idTokenResult.claims[requiredRole]) {
          next();
        } else {
          next('/inicio');
        }
      })
      .catch((error) => {
        console.log(error);
        next('/inicio');
      });
  } else {
    next();
  }
});

export default router;
