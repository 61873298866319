/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import * as fb from '../../firebase';

const state = () => ({
  companies: [],
  users: [],
});

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies;
  },

  addCompany({ companies }, company) {
    companies.push(company);
  },

  deleteCompany({ companies }, id) {
    companies.splice(
      companies.findIndex((company) => company.id === id),
      1,
    );
  },

  deleteUser({ users }, id) {
    users.splice(
      users.findIndex((user) => user.id === id),
      1,
    );
  },
  setUsers(state, users) {
    state.users = users;
  },

  addUser({ users }, user) {
    users.push(user);
  },
};

const actions = {
  async assignUserCredentials(context, { uid, roles }) {
    const idToken = await fb.auth.currentUser.getIdToken();
    const callable = fb.functions.httpsCallable('assignCustomClaim');
    await callable({ idToken, uid, roles });
  },
  async fetchCompanies({ commit }) {
    const companySnaps = await fb.companiesCollection.get();

    const companies = [];

    companySnaps.forEach((snap) => companies.push({ id: snap.id, ...snap.data() }));

    commit('setCompanies', companies);
  },

  async addCompany({ state, commit }, company) {
    return fb.companiesCollection.add({ ...company, index: state.companies.length }).then((doc) => {
      commit('addCompany', {
        id: doc.id,
        index: state.companies.length,
        ...company,
      });
      return doc.id;
    });
  },

  async deleteCompany({ commit }, id) {
    await fb.companiesCollection.doc(id).delete();
    const snaps = [
      await fb.employeesCollection(id).get(),
      await fb.categoriesCollection(id).get(),
      await fb.optionsCollection(id).get(),
      await fb.factorsCollection(id).get(),
      await fb.subfactorsCollection(id).get(),
      await fb.chartsCollection(id).get(),
      await fb.emailsCollection(id).get(),
      await fb.historyCollection(id).get(),
      await fb.marketsCollection(id).get(),
      await fb.foldersCollection(id).get(),
    ];
    snaps.forEach((snap) => {
      snap.forEach(async (doc) => {
        await doc.ref.delete();
      });
    });

    commit('deleteCompany', id);
  },

  async fetchUsers({ commit }) {
    const userSnaps = await fb.usersCollection.get();

    const users = [];

    userSnaps.forEach((snap) => users.push({ id: snap.id, ...snap.data() }));

    commit('setUsers', users);
  },

  async fetchUsersFromCompany({ commit }, companyId) {
    const userSnaps = await fb.usersCollection.where('companyId', '==', companyId).get();

    const users = [];

    userSnaps.forEach((snap) => users.push({ id: snap.id, ...snap.data() }));

    commit('setUsers', users);
  },

  async addUser(context, user) {
    const idToken = await fb.auth.currentUser.getIdToken();
    const callable = fb.functions.httpsCallable('createUser');
    await callable({ user, idToken });
    // return usersCollection.add({ ...user, index: state.users.length }).then((doc) => {
    //   commit('addUser', {
    //     id: doc.id,
    //     index: state.users.length,
    //     ...user,
    //   });
    //   return doc.id;
    // });
  },

  async deleteUser({ commit }, id) {
    const idToken = await fb.auth.currentUser.getIdToken();
    await fb.usersCollection.doc(id).delete();
    const callable = fb.functions.httpsCallable('deleteUser');
    await callable({ uid: id, idToken });

    commit('deleteUser', id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
