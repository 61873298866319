/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { foldersCollection, storage } from '../../firebase';

const state = () => ({
  files: [],
  folders: [],
});

const mutations = {
  setFolders(state, folders) {
    state.folders = folders;
  },
  addFolder({ folders }, folder) {
    folders.push(folder);
  },
  updateFolder({ folders }, folder) {
    folders.splice(
      folders.findIndex((u) => u.id === folder.id),
      1,
      folder,
    );
  },
  deleteFolder({ folders }, id) {
    folders.splice(
      folders.findIndex((folder) => folder.id === id),
      1,
    );
  },

  setFiles(state, val) {
    state.files = val;
  },
  addFile(state, val) {
    state.files.push(val);
  },
  updateFile({ files }, { refName, metadata }) {
    const prevFile = files.find((u) => u.metadata.name === refName);
    files.splice(
      files.findIndex((u) => u.metadata.name === refName),
      1,
      { ...prevFile, metadata },
    );
  },
  deleteFile(state, val) {
    state.files = state.files.filter((el) => el.file.name !== val.file.name);
  },
};

const actions = {
  async addFolder({ commit, rootState }, folder) {
    return foldersCollection(rootState.company.id)
      .add(folder)
      .then((doc) => {
        commit('addFolder', {
          id: doc.id,
          ...folder,
        });
        return doc.id;
      });
  },

  async updateFolder({ commit, rootState }, { id, ...folder }) {
    await foldersCollection(rootState.company.id)
      .doc(id)
      .update({ ...folder });

    commit('updateFolder', { id, ...folder });
  },

  async deleteFolder({ commit }, id) {
    await foldersCollection.doc(id).delete();

    commit('deleteFolder', id);
  },

  async fetchFolders({ commit, rootState }) {
    const folderSnaps = await foldersCollection(rootState.company.id).get();

    const folders = [];

    folderSnaps.forEach((snap) => folders.push({ id: snap.id, ...snap.data() }));

    commit('setFolders', folders);
  },

  async uploadFile({ commit, rootState }, file) {
    const lastIndex = file.name.lastIndexOf('.');
    const metadata = {
      customMetadata: {
        uploaderId: rootState.userProfile.id,
        name: file.name.substr(0, lastIndex),
        id: Date.now(),
      },
    };
    let ref;
    await storage
      .ref(`${rootState.company.id}/files/${file.folderId}/${file.name}`)
      .put(file)
      .then((el) => {
        ref = el.ref;
      });
    await storage
      .ref(`${rootState.company.id}/files/${file.folderId}/${file.name}`)
      .updateMetadata(metadata)
      .then((md) => commit('addFile', { file: ref, metadata: md }));
  },

  async updateFile({ commit, rootState }, file) {
    const metadata = { customMetadata: file.metadata.customMetadata };
    metadata.customMetadata.name = file.metadata.name;
    await storage
      .ref(`${rootState.company.id}/files/${file.folderId}/${file.metadata.refName}`)
      .updateMetadata(metadata)
      .then((md) => commit('updateFile', { refName: file.metadata.refName, metadata: md }));
  },

  async deleteFile({ commit, rootState }, file) {
    await storage.ref(`${rootState.company.id}/files/${file.folderId}/${file.file.name}`).delete();
    commit('deleteFile', file);
  },

  async fetchFiles({ commit, rootState }, folderId = '') {
    // const getFileBlob = (url) => new Promise((resolve, reject) => {
    //   const xhr = new XMLHttpRequest();
    //   xhr.open('GET', url);
    //   xhr.responseType = 'blob';
    //   xhr.onload = resolve;
    //   xhr.onerror = reject;
    //   xhr.send();
    // });

    const storageRef = storage.ref(`${rootState.company.id}/files/${folderId}/`);
    storageRef.listAll().then(async (result) => {
      const filePromises = result.items.map(async (fileRef) => {
        const fileURL = await fileRef.getDownloadURL();
        const metadata = await fileRef.getMetadata();
        return { file: fileRef, metadata, fileURL };
      });

      const files = await Promise.all(filePromises);
      commit('setFiles', files);
    });

    // const files = [];
    // storageRef.listAll().then((result) => {
    //   result.items.forEach(async (fileRef) => {
    //     // const fileURL = await getFileBlob(await fileRef.getDownloadURL());
    //     const fileURL = await fileRef.getDownloadURL();
    //     fileRef.getMetadata().then((md) => files.push({ file: fileRef, metadata: md, fileURL }));
    //   });
    // });
    // // .catch((error) => {
    // // });
    // commit('setFiles', files);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
