import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyC3YMUZOIky3oWp13-fcZ-WqLdRZYvBTeo',
  authDomain: 'elha-valoracion-de-puestos.firebaseapp.com',
  projectId: 'elha-valoracion-de-puestos',
  storageBucket: 'elha-valoracion-de-puestos.appspot.com',
  messagingSenderId: '652624783128',
  appId: '1:652624783128:web:ada64f4fd0a8b574a9606d',
  measurementId: 'G-9XWG7L0Y59',
};

firebase.initializeApp(firebaseConfig);
// firebase.functions().useFunctionsEmulator('http://localhost:8080');

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const { firestore } = firebase;
const functions = firebase.functions();

// collection references
const usersCollection = db.collection('users');
const companiesCollection = db.collection('companies');
const customSubfactorsCollection = db.collection('subfactors');
const historyCollection = (companyId) => companiesCollection.doc(companyId).collection('history');
const employeesCollection = (companyId) => companiesCollection.doc(companyId).collection('employees');
const categoriesCollection = (companyId) => companiesCollection.doc(companyId).collection('categories');
const optionsCollection = (companyId) => companiesCollection.doc(companyId).collection('options');
// const optionsCollection = (companyId) =>
// companiesCollection.doc(companyId).collection('options');
const chartsCollection = (companyId) => companiesCollection.doc(companyId).collection('charts');
const periodsCollection = (companyId) => companiesCollection.doc(companyId).collection('periods');
const factorsCollection = (companyId, periodId) => periodsCollection(companyId).doc(periodId).collection('factors');
const subfactorsCollection = (companyId, periodId) => periodsCollection(companyId).doc(periodId).collection('subfactors');
const foldersCollection = (companyId) => companiesCollection.doc(companyId).collection('folders');
const evaluationsCollection = (companyId, periodId) => periodsCollection(companyId).doc(periodId).collection('evaluations');
const emailsCollection = (companyId) => companiesCollection.doc(companyId).collection('emails');
const marketsCollection = (companyId) => companiesCollection.doc(companyId).collection('markets');

export {
  auth,
  db,
  storage,
  firestore,
  functions,
  customSubfactorsCollection,
  usersCollection,
  companiesCollection,
  employeesCollection,
  historyCollection,
  categoriesCollection,
  optionsCollection,
  chartsCollection,
  factorsCollection,
  subfactorsCollection,
  evaluationsCollection,
  foldersCollection,
  emailsCollection,
  marketsCollection,
  periodsCollection,
};
