/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { historyCollection } from '../../firebase';

const state = () => ({
  history: [],
});

const mutations = {
  setHistory(state, log) {
    state.history = log;
  },

  addLog({ history }, log) {
    history.push(log);
  },
};

const actions = {
  async fetchHistory({ commit, rootState }, year) {
    const historySnaps = await historyCollection(rootState.company.id)
      .where('createdOn', '<=', new Date(year, 12, 0)) // ultimo dia del mes
      .where('createdOn', '>=', new Date(year, 0, 1)) // primer dia del mes
      .get();

    const history = [];

    historySnaps.forEach((snap) => history.push({ id: snap.id, ...snap.data() }));

    commit('setHistory', history);
  },

  async addLog({ commit, rootState }, log) {
    return historyCollection(rootState.company.id)
      .add(log)
      .then((doc) => {
        commit('addLog', {
          id: doc.id,
          ...log,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
