import Vue from 'vue';
import Vuex from 'vuex';
import employees from '@/store/modules/employees';
import admin from './modules/admin';
import history from './modules/history';
import categories from './modules/categories';
import options from './modules/options';
import charts from './modules/charts';
import files from './modules/files';
import emails from './modules/emails';
import markets from './modules/markets';
import jobEvaluationProcess from './modules/job-evaluation-process';
import jobEvaluation from './modules/job-evaluation';
import * as fb from '../firebase';
import router from '../router/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    selectedDate: null,
    alert: {
      state: '',
      message: '',
    },
    company: {},
    userProfile: {
      id: '',
    },
    breadCrumbView: { views: [], currentView: {} },
  },
  mutations: {
    setCurrentView(state, currentView) {
      state.breadCrumbView = currentView;
    },
    setAlert({ alert }, newAlert) {
      Object.assign(alert, newAlert);
    },
    setCompany(state, val) {
      state.company = val;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setIsLoading(state, bool) {
      state.isLoading = bool;
    },
    setSelectedDate(state, date) {
      state.selectedDate = date;
    },
  },
  actions: {
    setIsLoading({ commit }, value) {
      commit('setIsLoading', value);
    },
    setSelectedDate({ commit }, value) {
      commit('setSelectedDate', value);
    },
    async login(context, { email, password }) {
      const result = await fb.auth.signInWithEmailAndPassword(email, password);
      const idToken = await result.user.getIdToken();
      const callable = fb.functions.httpsCallable('setCustomClaims');
      const data = await callable({ idToken });
      console.log(data);
      if (data && data.status === 'success') {
        fb.auth().currentUser.getIdToken(true);
      }
      router.push('/');
    },

    async getUserCredentials() {
      try {
        const idTokenResult = await fb.auth.currentUser.getIdTokenResult();
        console.log(idTokenResult);
        return idTokenResult.claims;
      } catch (error) {
        console.log(error);
        return {};
      }
    },

    async logout({ commit }) {
      await fb.auth.signOut();

      commit('setUserProfile', {});
      commit('setCompany', {});
      router.push('/');
    },

    async fetchRequiredData({ commit, dispatch, state }, uid) {
      commit('setIsLoading', true);
      await dispatch('fetchUserProfile', uid);
      if (state.userProfile && state.userProfile.companyId) {
        await dispatch('fetchCompany', state.userProfile.companyId);
      }
      commit('setIsLoading', false);
    },

    async fetchCompany({ commit }, id) {
      const companySnapshot = await fb.companiesCollection.doc(id).get();

      const company = {
        id: companySnapshot.id,
        ...companySnapshot.data(),
      };

      commit('setCompany', company);
    },

    async updateCompany({ commit }, { id, ...company }) {
      await fb.companiesCollection.doc(id).update({ ...company });

      commit('setCompany', { id, ...company });
    },

    async updateUserProfile({ commit }, { id, ...user }) {
      await fb.usersCollection.doc(id).update({ ...user });

      commit('setUserProfile', { id, ...user });
    },

    async updateUser(context, { id, ...user }) {
      await fb.usersCollection.doc(id).update({ ...user });
    },

    async fetchUserProfile({ commit }, uid) {
      const userSnapshot = await fb.usersCollection.doc(uid).get();

      const userProfile = {
        id: userSnapshot.id,
        ...userSnapshot.data(),
      };

      commit('setUserProfile', userProfile);
    },

    async updatePassword(context, newPassword) {
      await fb.auth.currentUser.updatePassword(newPassword);
    },

    async uploadLogo({ state }, file) {
      await fb.storage.ref(`${state.company.id}/logo`).put(file);
    },

    async uploadSignature({ state }, file) {
      await fb.storage.ref(`${state.company.id}/signature`).put(file);
    },

    async getLogo({ state }) {
      const storageRef = fb.storage.ref(`${state.company.id}/`);
      const result = storageRef.child('logo');
      return result;
    },

    async getSignature({ state }) {
      const storageRef = fb.storage.ref(`${state.company.id}/`);
      const result = storageRef.child('signature');
      return result;
    },
  },
  modules: {
    employees,
    admin,
    history,
    categories,
    options,
    charts,
    jobEvaluationProcess,
    jobEvaluation,
    files,
    emails,
    markets,
  },
});
