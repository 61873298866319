/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { chartsCollection, db } from '../../firebase';

const state = () => ({
  charts: [],
});

const mutations = {
  setCharts(state, charts) {
    state.charts = charts;
  },

  addChart({ charts }, chart) {
    charts.push(chart);
  },

  updateChart({ charts }, chart) {
    charts.splice(
      charts.findIndex((u) => u.id === chart.id),
      1,
      chart,
    );
  },

  deleteChart({ charts }, id) {
    charts.splice(
      charts.findIndex((chart) => chart.id === id),
      1,
    );
  },

  updateChartsBatch(state, charts) {
    const chartsId = charts.map((chart) => chart.id);
    state.charts = [
      ...charts,
      ...state.charts.filter((chart) => !chartsId.includes(chart.id)),
    ].sort((a, b) => (a.index > b.index ? 1 : -1));
  },
};

const actions = {
  async fetchCharts({ commit, rootState }) {
    const chartSnaps = await chartsCollection(rootState.company.id).orderBy('index').get();

    const charts = [];

    chartSnaps.forEach((snap) => charts.push({ id: snap.id, ...snap.data() }));

    commit('setCharts', charts);
  },

  async addChart({ state, commit, rootState }, chart) {
    return chartsCollection(rootState.company.id)
      .add({ ...chart, index: state.charts.length })
      .then((doc) => {
        commit('addChart', {
          id: doc.id,
          index: state.charts.length,
          ...chart,
        });
        return doc.id;
      });
  },

  async updateChart({ commit, rootState }, { id, ...chart }) {
    await chartsCollection(rootState.company.id)
      .doc(id)
      .update({ ...chart });

    commit('updateChart', { id, ...chart });
  },

  async deleteChart({ commit, rootState }, id) {
    await chartsCollection(rootState.company.id).doc(id).delete();

    commit('deleteChart', id);
  },

  async updateChartsBatch({ commit, rootState }, charts) {
    const batch = db.batch();

    charts.forEach(({ id, ...args }) => {
      batch.update(chartsCollection(rootState.company.id).doc(id), { ...args });
    });

    await batch.commit();

    commit('updateChartsBatch', charts);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
