/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './styles.scss';
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd';
import * as icons from 'vue-unicons/dist/icons';
const modules = [
  icons.uniCloud,
  icons.uniChartPieAlt,
  icons.uniAnalytics,
  icons.uniInfo,
  icons.uniMailbox,
  icons.uniChart,
  icons.uniStore,
  icons.uniExternalLinkAlt,
  icons.uniSitemap,
  icons.uniGlobe,
  icons.uniCheck,
  icons.uniHome,
  icons.uniFolderOpen,
  icons.uniMoneyBill,
  icons.uniSetting,
  icons.uniSuitcase,
  icons.uniBalanceScale,
  icons.uniUserCircle,
  icons.uniArrowUp,
  icons.uniArrowDown,
  icons.uniCalculator,
  icons.uniPercentage,
  icons.uniArrowCircleRight,
  icons.uniGripHorizontalLine,
  icons.uniCheckCircle,
  icons.uniMultiply,
  icons.uniRulerCombined,
  icons.uniSearchAlt,
  icons.uniPuzzlePiece,
  icons.uniCheck,
  icons.uniDraggabledots,
  icons.uniArrowLeft,
  icons.uniInfoCircle,
  icons.uniExclamationTriangle,
  icons.uniCheckCircle,
  icons.uniTimes,
  icons.uniPlus,
  icons.uniFacebookF,
  icons.uniInstagram,
  icons.uniLinkedinAlt,
  icons.uniSearch,
  icons.uniAngleLeft,
  icons.uniEdit,
  icons.uniPlusCircle,
  icons.uniMinusCircle,
  icons.uniTrash,
  icons.uniAngleRight,
  icons.uniFileImport,
  icons.uniFileUpload,
  icons.uniFileDownload,
  icons.uniEllipsisH,
  icons.uniPen,
  icons.uniEye,
  icons.uniSortAmountUp,
  icons.uniSortAmountDown,
  icons.uniAngleDown,
  icons.uniUser,
  icons.uniMars,
  icons.uniVenus,
  icons.uniSuitcase,
  icons.uniEyeSlash,
  icons.uniChartBar,
  icons.uniChartBarAlt,
  icons.uniChartPie,
  icons.uniChartLine,
  icons.uniSave,
  icons.uniRedo,
  icons.uniArrowRight,
  icons.uniCog,
  icons.uniElipsisDoubleVAlt,
  icons.uniBan,
  icons.uniAngleDoubleLeft,
  icons.uniAngleDoubleRight,
  icons.uniFolderOpen,
  icons.uniEnvelope,
  icons.uniCalender,
  icons.uniCalendarSlash,
  icons.uniChart,
  icons.uniTable,
  icons.uniApps,
  icons.uniMinus,
  icons.uniPercentage,
  icons.uniHourglass,
  icons.uniAdjustHalf,
  icons.uniFileEditAlt,
  icons.uniFileBlank,
  icons.uniEllipsisV,
  icons.uniCircle,
  icons.uniFolderPlus,
  icons.uniFolder,
  icons.uniSliderHRange,
  icons.uniArrowsV,
  icons.uniFilter,
  icons.uniSort,
  icons.uniUploadAlt,
  icons.uniListUl,
  icons.uniAlignJustify,
  icons.uniBars,
  icons.uniSetting,
  icons.uniSignout,
  icons.uniHistory,
  icons.uniEnvelopeUpload,
  icons.uniFile,
  icons.uniExclamationOctagon,
  icons.uniX,
  icons.uniRuler,
  icons.uniTachometerFast,
  icons.uniEnvelopeEdit,
  icons.uniUsersAlt,
  icons.uniThumbsUp,
  icons.uniNotebooks,
];

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { auth } from './firebase';

import './validation';

Vue.component('font-awesome-icon', FontAwesomeIcon);

Unicon.add(modules);
Vue.use(Unicon);

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }

  if (user) {
    store.dispatch('fetchRequiredData', user.uid);
  }
});
