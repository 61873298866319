/* eslint-disable */
import { extend } from 'vee-validate';
import {
  required,
  numeric,
  between,
  oneOf,
  min_value,
  max_value,
  excluded,
  confirmed,
  min,
  email,
  is,
} from 'vee-validate/dist/rules';

extend('email', {
  ...email,

  message: 'Formato de email invalido.',
});

extend('required', {
  ...required,

  validate(value) {
    switch (typeof value) {
      case 'object':
        return value.id !== '';
      case 'string':
        return value !== '';
      case 'number':
        return value >= 0;
      case 'boolean':
        return value;
      default:
        return value;
    }
  },

  message: 'Este campo es obligatorio.',
});

extend('between', {
  ...between,
  message: 'El campo {_field_} debe estar entre {min} y {max}.',
});

extend('minmax', {
  validate(value, { min, max }) {
    const { length } = value;

    return length >= min && length <= max;
  },

  params: ['min', 'max'],

  message: 'Debe contener entre {min} y {max} caracteres.',
});

extend('numeric', {
  ...numeric,
  message: 'Solo debe contener caracteres numéricos.',
});

extend('positive', {
  validate(value) {
    return value >= 0;
  },

  message: 'Debe contener un número positivo.',
});

extend('min_date', {
  validate(value, min) {
    return new Date(value) >= new Date(min);
  },

  message: 'Debe contener una fecha mayor o igual a la fecha anterior.',
});

extend('oneOf', {
  ...oneOf,

  message: 'Solo puede contener uno de los valores definidos.',
});

extend('isValidId', {
  ...oneOf,

  message: 'Id de empleado invalido.',
});

extend('min_value', {
  ...min_value,

  message: 'Debe contener un número mayor o igual a {min}',
});

extend('max_value', {
  ...max_value,

  message: 'Debe contener un número menor o igual a {max}',
});

extend('min', {
  ...min,

  message: 'Debe contener por lo menos {length} caracteres.',
});

extend('excluded', {
  ...excluded,

  message: 'Este valor ya fue utiizado.',
});

extend('confirmed', {
  ...confirmed,

  message: 'Debe coincidir con el campo de confirmación.',
});

extend('sumShouldEqual', {
  validate(value, { total, values }) {
    const sum = values.reduce((sum, value) => sum + parseInt(value, 10), 0);
    return total === sum;
  },
  params: ['total', 'values'],
  message: 'La suma de valores debe ser igual a {total}.',
});

extend('sumShouldLessThan', {
  validate(value, { total, values }) {
    const sum = values.reduce((sum, value) => sum + parseInt(value, 10), 0);
    console.log(total <= sum);
    return total >= sum;
  },
  params: ['total', 'values'],
  message: 'La suma de valores debe ser menor o igual a {total}.',
});

extend('is', {
  ...is,
  message: 'El valor debe ser igual a {value}',
});
