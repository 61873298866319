/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { categoriesCollection, db } from '../../firebase';

const state = () => ({
  categories: [],
});

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },

  addCategory({ categories }, category) {
    categories.push(category);
  },

  updateCategory({ categories }, category) {
    categories.splice(
      categories.findIndex((u) => u.id === category.id),
      1,
      category,
    );
  },

  deleteCategory({ categories }, id) {
    categories.splice(
      categories.findIndex((category) => category.id === id),
      1,
    );
  },

  updateCategoriesBatch(state, categories) {
    const categoriesId = categories.map((category) => category.id);
    state.categories = [
      ...categories,
      ...state.categories.filter((category) => !categoriesId.includes(category.id)),
    ].sort((a, b) => (a.index > b.index ? 1 : -1));
  },
};

const actions = {
  async fetchCategories({ commit, rootState }) {
    const categorySnaps = await categoriesCollection(rootState.company.id).orderBy('index').get();

    const categories = [];

    categorySnaps.forEach((snap) => categories.push({ id: snap.id, ...snap.data() }));

    commit('setCategories', categories);
  },

  async addCategory({ state, commit, rootState }, category) {
    return categoriesCollection(rootState.company.id)
      .add({ ...category, index: state.categories.length })
      .then((doc) => {
        commit('addCategory', {
          id: doc.id,
          index: state.categories.length,
          ...category,
        });
        return doc.id;
      });
  },

  async updateCategory({ commit, rootState }, { id, ...category }) {
    await categoriesCollection(rootState.company.id)
      .doc(id)
      .update({ ...category });

    commit('updateCategory', { id, ...category });
  },

  async deleteCategory({ commit, rootState }, id) {
    await categoriesCollection(rootState.company.id).doc(id).delete();

    commit('deleteCategory', id);
  },

  async updateCategoriesBatch({ commit, rootState }, categories) {
    const batch = db.batch();

    categories.forEach(({ id, ...args }) => {
      batch.update(categoriesCollection(rootState.company.id).doc(id), { ...args });
    });

    await batch.commit();

    commit('updateCategoriesBatch', categories);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
