/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable no-param-reassign */
import { employeesCollection, db } from '../../firebase';

const state = () => ({
  employees: [],
});

const mutations = {
  setEmployees(state, employees) {
    state.employees = employees;
  },

  addEmployee({ employees }, employee) {
    employees.push(employee);
  },

  updateEmployee({ employees }, employee) {
    employees.splice(
      employees.findIndex((u) => u.id === employee.id),
      1,
      employee,
    );
  },

  deleteEmployee({ employees }, id) {
    employees.splice(
      employees.findIndex((employee) => employee.id === id),
      1,
    );
  },
};

const actions = {
  async fetchEmployees({ commit, rootState }) {
    let employeeSnaps;
    if (rootState.userProfile.restrictions && rootState.userProfile.restrictions.length) {
      const { restrictions } = rootState.userProfile;
      let query = employeesCollection(rootState.company.id);
      restrictions.forEach((restriction) => {
        query = query.where(restriction.categoryId, '==', restriction.optionId);
      });

      employeeSnaps = await query.get();
    } else employeeSnaps = await employeesCollection(rootState.company.id).get();

    const employees = [];

    employeeSnaps.forEach((snap) => employees.push({ id: snap.id, ...snap.data() }));

    commit('setEmployees', employees);
  },

  async addEmployee({ commit, rootState }, employee) {
    return employeesCollection(rootState.company.id)
      .add(employee)
      .then((doc) => {
        commit('addEmployee', {
          id: doc.id,
          ...employee,
        });
      });
  },

  async updateEmployee({ commit, rootState }, { id, ...employee }) {
    await employeesCollection(rootState.company.id)
      .doc(id)
      .update({ ...employee });

    commit('updateEmployee', { id, ...employee });
  },

  async deleteEmployee({ commit, rootState }, id) {
    await employeesCollection(rootState.company.id).doc(id).delete();

    commit('deleteEmployee', id);
  },

  async addEmployeesBatch({ commit, rootState }, employees) {
    const employeesBatch = [];
    while (employees.length) {
      employeesBatch.push(employees.splice(0, 500));
    }
    employeesBatch.forEach(async (employeesSet) => {
      const batch = db.batch();

      employeesSet.forEach((employee) => {
        const docRef = employeesCollection(rootState.company.id).doc();

        batch.set(docRef, employee);

        employee.id = docRef.id;
      });

      await batch.commit();

      employeesSet.forEach((employee) => commit('addEmployee', employee));
    });
  },

  async updateEmployeesBatch({ commit, rootState }, employees) {
    const employeesBatch = [];
    while (employees.length) {
      employeesBatch.push(employees.splice(0, 500));
    }
    employeesBatch.forEach(async (employeesSet) => {
      const batch = db.batch();

      employeesSet.forEach((employee) => {
        const docRef = employeesCollection(rootState.company.id).doc(employee.id);
        const tempEmployee = { ...employee };
        delete tempEmployee.id;
        batch.update(docRef, tempEmployee);
      });

      await batch.commit();

      employeesSet.forEach((employee) => commit('updateEmployee', employee));
    });
  },

  async batchDelete({ commit, rootState }, ids) {
    const idsBatch = [];
    while (ids.length) {
      idsBatch.push(ids.splice(0, 500));
    }
    idsBatch.forEach(async (idsSet) => {
      const batch = db.batch();

      idsSet.forEach((id) => {
        batch.delete(employeesCollection(rootState.company.id).doc(id));
      });

      await batch.commit();

      idsSet.forEach((id) => commit('deleteEmployee', id));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
