/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { marketsCollection, db } from '../../firebase';

const state = () => ({
  markets: [],
});

const mutations = {
  setMarkets(state, markets) {
    state.markets = markets;
  },

  addMarket({ markets }, market) {
    markets.push(market);
  },

  updateMarket({ markets }, market) {
    markets.splice(
      markets.findIndex((u) => u.id === market.id),
      1,
      market,
    );
  },

  deleteMarket({ markets }, id) {
    markets.splice(
      markets.findIndex((market) => market.id === id),
      1,
    );
  },

  updateMarketsBatch(state, markets) {
    const marketsId = markets.map((market) => market.id);
    state.markets = [
      ...markets,
      ...state.markets.filter((market) => !marketsId.includes(market.id)),
    ].sort((a, b) => (a.index > b.index ? 1 : -1));
  },
};

const actions = {
  async fetchMarkets({ commit, rootState }) {
    const marketSnaps = await marketsCollection(rootState.company.id).orderBy('index').get();

    const markets = [];

    marketSnaps.forEach((snap) => markets.push({ id: snap.id, ...snap.data() }));

    commit('setMarkets', markets);
  },

  async addMarket({ state, commit, rootState }, market) {
    return marketsCollection(rootState.company.id)
      .add({ ...market, index: state.markets.length })
      .then((doc) => {
        commit('addMarket', {
          id: doc.id,
          index: state.markets.length,
          ...market,
        });
        return doc.id;
      });
  },

  async updateMarket({ commit, rootState }, { id, ...market }) {
    await marketsCollection(rootState.company.id)
      .doc(id)
      .update({ ...market });

    commit('updateMarket', { id, ...market });
  },

  async deleteMarket({ commit, rootState }, id) {
    await marketsCollection(rootState.company.id).doc(id).delete();

    commit('deleteMarket', id);
  },

  async updateMarketsBatch({ commit, rootState }, markets) {
    const batch = db.batch();

    markets.forEach(({ id, ...args }) => {
      batch.update(marketsCollection(rootState.company.id).doc(id), { ...args });
    });

    await batch.commit();

    commit('updateMarketsBatch', markets);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
