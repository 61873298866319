/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable no-param-reassign */
import { optionsCollection, db } from '@/firebase';

const state = () => ({
  options: [],
});

const mutations = {
  setOptions(state, options) {
    state.options = options;
  },

  addOption({ options }, option) {
    options.push(option);
    options = options.sort((a, b) => (a.index > b.index ? 1 : -1));
  },

  updateOption({ options }, option) {
    options.splice(
      options.findIndex((u) => u.id === option.id),
      1,
      option,
    );
    options = options.sort((a, b) => (a.index > b.index ? 1 : -1));
  },

  deleteOption({ options }, id) {
    options.splice(
      options.findIndex((option) => option.id === id),
      1,
    );
  },

  updateOptionsBatch(state, options) {
    const optionsId = options.map((option) => option.id);
    state.options = [
      ...options,
      ...state.options.filter((option) => !optionsId.includes(option.id)),
    ].sort((a, b) => (a.index > b.index ? 1 : -1));
  },
};

const actions = {
  async fetchOptions({ commit, rootState }) {
    const optionSnaps = await optionsCollection(rootState.company.id).orderBy('index').get();

    const options = [];

    optionSnaps.forEach((snap) => options.push({ id: snap.id, ...snap.data() }));

    commit('setOptions', options);
  },

  async addOption({ commit, rootState }, option) {
    return optionsCollection(rootState.company.id)
      .add(option)
      .then((doc) => {
        commit('addOption', {
          id: doc.id,
          ...option,
        });
      });
  },

  async updateOptionsBatch({ commit, rootState }, options) {
    const batch = db.batch();

    options.forEach(({ id, ...args }) => {
      batch.update(optionsCollection(rootState.company.id).doc(id), { ...args });
    });

    await batch.commit();

    commit('updateOptionsBatch', options);
  },

  async addOptionsBatch({ commit, rootState }, options) {
    const batch = db.batch();

    options.forEach((option) => {
      const docRef = optionsCollection(rootState.company.id).doc();

      batch.set(docRef, option);

      option.id = docRef.id;
    });

    await batch.commit();

    options.forEach((option) => commit('addOption', option));
  },

  async deleteOptionsBatch({ commit, rootState }, options) {
    const batch = db.batch();

    options.forEach((id) => {
      batch.delete(optionsCollection(rootState.company.id).doc(id));
    });

    await batch.commit();

    options.forEach((id) => commit('deleteOption', id));
  },

  async updateOption({ commit, rootState }, { id, ...option }) {
    await optionsCollection(rootState.company.id)
      .doc(id)
      .update({ ...option });

    commit('updateOption', { id, ...option });
  },

  async deleteOption({ commit, rootState }, id) {
    await optionsCollection(rootState.company.id).doc(id).delete();

    commit('deleteOption', id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
