/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { emailsCollection, functions } from '../../firebase';

const state = () => ({
  emails: [],
});

const mutations = {
  setEmails(state, emails) {
    state.emails = emails;
  },
};

const actions = {
  async fetchEmails({ commit, rootState }) {
    const emailSnaps = await emailsCollection(rootState.company.id).get();

    const emails = [];

    emailSnaps.forEach(async (snap) => {
      const adressesSnaps = await emailsCollection(rootState.company.id)
        .doc(snap.id)
        .collection('addresses')
        .get();
      const addresses = [];
      adressesSnaps.forEach(async (adressSnap) => {
        const eventsSnaps = await emailsCollection(rootState.company.id)
          .doc(snap.id)
          .collection('addresses')
          .doc(adressSnap.id)
          .collection('events')
          .get();
        const events = [];
        eventsSnaps.forEach(async (eventSnap) => {
          events.push({ id: eventSnap.id, ...eventSnap.data() });
        });

        addresses.push({ id: adressSnap.id, ...adressSnap.data(), events });
      });
      emails.push({ id: snap.id, ...snap.data(), addresses });
    });
    console.log(emails);

    commit('setEmails', emails);
  },

  async sendEmail({ rootState }, data) {
    let res = {};

    try {
      const callable = functions.httpsCallable('emailMessage');
      res = await callable({ companyId: rootState.company.id, ...data });
      return res;
    } catch (e) {
      console.log(e);
      return res;
    }
  },

  async getEmailStatistics() {
    let data = {};
    try {
      const callable = functions.httpsCallable('getEmailStatistics');
      data = await callable(data);
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
